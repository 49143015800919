import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Layout/Navbar/Navbar';
import Blog from './Components/Blog/Blog';
import Media from './Components/MediaSpotlight/Media';
import News from './Components/News/News';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navbar><Blog /></Navbar>} />
        <Route path="/press" element={<Navbar><Media /></Navbar>} />
        <Route path="/:url/:medianame/:publisheddate/:title" element={<Navbar><News /></Navbar>} />
      </Routes>
    </Router>
  );
}

export default App;
