// import React from 'react';
// import Handshake from '../Images/banner.svg';

// export default function Media() {
//   return (
//     <div className='font-[lato]'>
//       <div className='w-full h-[35vh] fixed relative' >        
//         <div className='relative '>
//           <div className='absolute top-0 left-0 w-full h-full bg-[#085690BF] text-white text-center bg-black bg-opacity-20 h-full bg-white align-middle '><div className='bold'>Media</div> Spotlight</div>
//           <img className='w-full' src={Handshake} alt='Handshake' />
//         </div>
//         {/* <div >
//           <p className='bg-black bg-opacity-20 h-[5vh] w-[10v'>hhh</p>
//         </div> */}
//       </div>
//         <div className='text-[#085690] w-full align-center text-center justify-center flex'>
//             <div className='w-[60vh] bg-green-100'>
//                 Decision Minds frequently appears in top global business and IT magazines, recognized for its industry-leading innovations and solutions.
//             </div>            
//         </div>
//     </div>
//   );
// }

// import React from 'react';
// import Handshake from '../Images/banner.svg';

// export default function Media() {
//   return (
//     <div className='font-[lato] relative'>
//       <div className='w-full h-[51vh] fixed relative overflow-hidden'>
//         <div className='relative'>
//           <img className='w-full h-full object-cover' src={Handshake} alt='Handshake' />
//           <div className='absolute top-0 left-0 w-full h-full bg-[#085690BF]'></div>
//           <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center'>
//             <div className='font-bold  flex align-middle h-full bg-green-200 text-lg'>Media Spotlight</div>
//           </div>
//         </div>
//       </div>
//       <div className='relative text-[#085690] w-full bg-green-600 text-center'>
//         <div className='w-[60vh] bg-green-100 mx-auto p-4'>
//           Decision Minds frequently appears in top global business and IT magazines, recognized for its industry-leading innovations and solutions.
//         </div>
//       </div>
//     </div>
//   );
// }

import React from 'react';
import Handshake from '../Images/Mediaimg.svg';
import PublicationSection from './PublicationSection';
import {MediaPublishedJson} from './MediaPublishedJson';
import GeneralContactUs from '../ContactUs/GeneralContactUs';

export default function Media() {
  return (
    <div className='font-[lato] relative '>
      <div className='w-full fixed relative overflow-hidden'>
        <div className='relative'>
          <img className='w-full h-full object-cover' src={Handshake} alt='Handshake' />
          <div className='absolute top-0 left-0 w-full h-full bg-[#085690BF]'></div>
          <div className='absolute inset-0 flex items-center justify-center  text-white text-center'>
            <div className='font-thin text-xl bg-[#38668a] px-10 py-5'>
              <span className='font-extrabold'>News</span></div>
          </div>
        </div>
      </div>
      <div className='relative text-[#085690] w-full text-center'>
        <div className='lg:w-1/2 py-10 mx-auto text-lg font-semibold>' >
          Decision Minds frequently appears in top global business and IT magazines, recognized for its industry-leading innovations and solutions.
        </div>
      </div>
      <div>
        <PublicationSection Publications={MediaPublishedJson} />
      </div>
      <div >
        <GeneralContactUs />
      </div>
    </div>
  );
}

