import React from 'react'

export default function ZohoContact() {

  let contachUs = `<!-- Note :
- You can modify the font style and form style to suit your website. 
- Code lines with comments Do not remove this code are required for the form to work properly, make sure that you do not remove these lines of code. 
- The Mandatory check script can modified as to suit your business needs. 
- It is important that you test the modified form before going live.-->
<div id='crmWebToEntityForm' class='zcwf_lblTopBottom crmWebToEntityForm' style='background-color: transparent;color:#fff;width:400px;'>
<meta name='viewport' content='width=device-width, initial-scale=1.0'>
<META HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'>

<div class='wf_customMessageBox'  id='wf_splash' style='display:none'>
 <div class="wf_customCircle">
 <div class="wf_customCheckMark"></div>
</div>
 <span id="wf_splash_info"></span>
</p>
</div>
<form id='webform1751067000027518001' name=WebToLeads1751067000027518001 accept-charset='UTF-8'>
<input type='text' style='display:none;' name='xnQsjsdp' value='a0afea1ad563e2116c6e2918866e825a4e4f8e6a35afdee44ddeb0f10dc15f18'></input> 
<input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
<input type='text' style='display:none;' name='xmIwtLD' value='404180c112684976c444e24df328d852d2604f1f7e3be693b2cf2fde7476f2072a9ffefe19a9d03cb919f80873591cf2'></input> 
<input type='text'  style='display:none;' name='actionType' value='TGVhZHM='></input>
<input type='text' style='display:none;' name='returnURL' value='null' > </input>
  <!-- Do not remove this code. -->
  
<div class='zcwf_row'>
<div  class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
<label for='Last_Name' style='display:none'>Your Name<span style='color:red;'>*</span></label>
</div>
<div class='zcwf_col_fld'>
<input type='text' id='Last_Name' name='Last Name' maxlength='80' placeholder="Your Name" class="zoho-input"></input>
<div class='zcwf_col_help'>
</div>
</div>
</div>
<div class='zcwf_row'>
<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
<label for='Email' style='display:none'>Email<span style='color:red;'>*</span></label>
</div>
<div class='zcwf_col_fld'>
<input type='text' ftype='email' autocomplete='false' id='Email' name='Email' crmlabel='' maxlength='100' placeholder="Your Email" class="zoho-input"></input>
<div class='zcwf_col_help'>
</div>
</div>
</div>
<div class='zcwf_row'>
<div class='zcwf_col_lab' style='font-size:12px; font-family: Arial;'>
<label for='Description' style='display:none'>Message<span style='color:red;'>*</span></label>
</div>
<div class='zcwf_col_fld'>
<textarea id='Description' name='Description' rows="4" placeholder="Message" class="zoho-textArea"></textarea>
<div class='zcwf_col_help'>
</div>
</div>
</div>
<div class='zcwf_row'>
<div class='zcwf_col_lab'>
</div>
<div class='zcwf_col_fld zoho-button-container'>
<input type='submit' id='formsubmit' class='formsubmit zcwf_button zoho-button' value='Connect' title='Connect'>
<input type='reset' class='zcwf_button' name='reset' value='Reset' title='Reset' style='display:none;'>
</div>
</div>
 </form>
</div>`

  return (
    <div>
      <div id='contact' className=' pr-10' dangerouslySetInnerHTML={{ __html: contachUs }} />

    </div>
  )
}
