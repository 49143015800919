import React, { useEffect, useState, useRef } from 'react';
import DMLogo from '../../Components/Images/DMLogo.png';
import { IoMenuOutline, IoCloseOutline, IoChevronDownOutline } from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';

function useOutsideClick(ref, onOutsideClick) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
}

export default function Header({ scrollToContact }) {
  const wrapperRef = useRef(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDropDown, setOpenDropdown] = useState({});
  const [subItemsData, setSubItemsData] = useState([]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navItems = [
    {
      label: 'Home',
      link: 'https://www.decisionminds.com/'
    },
    {
      label: 'About Us',
      link: 'https://www.decisionminds.com/about-us.php',
      subItems: {
        0: [
          { label: 'Team', link: 'https://www.decisionminds.com/index.php#team' },
          { label: 'Careers', link: 'https://decisionminds.zohorecruit.com/jobs/Careers/' },
          { label: 'News', link: 'https://finance.decisionminds.com/press' },
        ]
      }
    },
    {
      label: 'Services',
      subItems: {
        0: [
          { label: 'Cloud Analytics', link: 'https://www.decisionminds.com/cloud-analytics.php' },
          { label: 'Data Virtualization', link: 'https://www.decisionminds.com/data-virtualization.php' },
          { label: 'Artificial Intelligence + ML', link: 'https://www.decisionminds.com/artificial-intelligence.php' },
          { label: 'Digital Engineering', link: 'https://www.decisionminds.com/digital-engineering.php' },
          { label: 'Process Automation', link: 'https://www.decisionminds.com/process-automation.php' },
          { label: 'Cloud Services', link: 'https://www.decisionminds.com/cloud-services.php' },
          // Add more subItems as needed
        ],
      }
    },
    {
      label: 'Products',
      subItems: {
        0: [
          { label: 'DecisionHUB', link: 'https://www.decisionminds.com/index.php#decisionHUB' },
          { label: 'DataHUB', link: 'https://www.decisionminds.com/index.php#dataHUBg' },
          { label: 'Success4', link: 'https://www.decisionminds.com/index.php#Success4' },
          // Add more subItems as needed
        ]
      }
    },
    {
      label: 'Industry Solutions',
      subItems: {
        0: [
          { label: 'Credit Unions and Regional Banks', link: 'https://finance.decisionminds.com/' },
          // Add more subItems as needed
        ]
      }
    },
    {
      label: 'Resources',
      subItems: {
        0: [
          { label: 'Case studies', link: 'https://www.decisionminds.com/case-studies.php' },
          { label: 'Knowledge Hub', link: 'https://www.decisionminds.com/knowledge-hub.php' },
          { label: 'Trending Videos', link: 'https://www.decisionminds.com/trending-video-section.php' },
          // Add more subItems as needed
        ]
      }
    },
    {
      label: 'Team',
      link: 'https://www.decisionminds.com/index.php#team'
    },
    {
      label: 'Careers',
      link: 'https://decisionminds.zohorecruit.com/jobs/Careers/'
    },
  ];

  const onOutsideClick = () => {
    setOpenDropdown({});
  };

  useOutsideClick(wrapperRef, onOutsideClick);

  const OpenDropdown = (subItems, label) => {
    setOpenDropdown(prevState => ({
      ...prevState,
      [label]: !prevState[label] // Toggle dropdown state only if it's currently closed
    }));
    setSubItemsData(subItems[0]);
  };

  return (
    <>
      {/* Desktop Header */}
      <div className='hidden lg:block px-[5%]'>
        <div className='flex justify-between p-5'>
          <img src={DMLogo} width='200' onClick={() => window.open('https://www.decisionminds.com/', '_self')} className='cursor-pointer' />
          <ol className='flex xl:gap-10 gap-2  items-center justify-center'>
            {navItems.map((headerData, key) => (
              <div className='flex gap-2 items-center' key={key}>
                <div onClick={() => {OpenDropdown(headerData.subItems, headerData.label)}} className='hover:text-veniceBlue cursor-pointer flex  align-center items-center'>
                  <Link to={headerData.link} >{headerData.label}</Link>
                  {headerData.subItems && (
                    <IoChevronDownOutline
                      className={`ml-2 ${openDropDown[headerData.label] ? 'text-veniceBlue' : ''}`}
                    />
                  )}
                </div>
                {openDropDown[headerData.label] && (
                  <div className='transition ease-in-out absolute top-16' ref={wrapperRef}>
                    <div className='bg-white flex flex-col rounded shadow-lg w-[13vw]'>
                      {subItemsData.map((subItems, subItemsKey) => (
                        <Link to={subItems.link} className='text-black  p-2 hover:text-veniceBlue' key={subItemsKey}>{subItems.label}</Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <button className='bg-veniceBlue px-5 py-2 text-center text-white rounded-[5px]' onClick={scrollToContact} >Contact</button>
          </ol>
        </div>
      </div>

      {/* Mobile Header */}
      <div className='lg:hidden block px-5 py-3 bg-white'>
        <div className='flex justify-between items-center'>
          <Link to='https://www.decisionminds.com/'><img src={DMLogo} width='150' alt='DM Logo' /></Link>
          <button onClick={toggleMobileMenu} className='text-2xl'>
            {isMobileMenuOpen ? <IoCloseOutline /> : <IoMenuOutline />}
          </button>
        </div>
      </div>

      {/* Mobile Menu Popup */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 z-50 ">
          <div className="absolute top-0 right-0 p-4">
            <button onClick={toggleMobileMenu} className="text-3xl text-white">
              <IoCloseOutline />
            </button>
          </div>
          <div className="flex flex-col m-10 bg-white rounded-xl p-5">
            <ol className="text-black text-md h-full">
              {navItems.map((item, index) => (
                <div key={index} className="my-4 ">
                  <div className="flex justify-between">
                    <Link to={item.link} onClick={toggleMobileMenu} className="hover:text-[#085690] p-2">
                      {item.label}
                    </Link>
                    {item.subItems && (
                      <IoChevronDownOutline
                        className={`cursor-pointer ml-2 ${openDropDown[item.label] ? 'text-veniceBlue' : ''}`}
                        onClick={() => OpenDropdown(item.subItems, item.label)}
                      />
                    )}
                  </div>
                  {openDropDown[item.label] && (
                    <div className="pl-4 mt-2 shadow-md p-2 rounded-lg bg-white" ref={wrapperRef}>
                      {subItemsData.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.link}
                          onClick={toggleMobileMenu}
                          className="block py-1 hover:text-[#085690]"
                        >
                          {subItem.label}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
               <button className='bg-veniceBlue px-5 py-2 text-center text-white rounded-[5px] w-full' onClick={scrollToContact} >Contact Us</button>
            </ol>
          </div>
        </div>
      )}
    </>
  );
}
