import React, { useState } from 'react';
import { MediaPublishedJson } from './MediaPublishedJson'; // Assuming MediaPublishedJson is in the same directory
import News from '../News/News';
import { useParams } from 'react-router-dom';

export default function PublicationSection() {
    const publications = MediaPublishedJson.Publications;
    const [currentPage, setCurrentPage] = useState(1);
    const publicationsPerPage = 10;
    const totalPages = Math.ceil(publications.length / publicationsPerPage);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const renderPageNumbers = getPageNumbers().map((number) => (
        <button
            key={number}
            className={`px-3 py-2 bg-blue-500 text-white rounded ${number === currentPage ? 'bg-blue-700 border-b-4 border-blue-700' : ''}`}
            onClick={() => handlePageClick(number)}
        >
            {number}
        </button>
    ));

    const indexOfLastPublication = currentPage * publicationsPerPage;
    const indexOfFirstPublication = indexOfLastPublication - publicationsPerPage;
    const currentPublications = publications.slice(indexOfFirstPublication, indexOfLastPublication);

    const showArrows = totalPages > 1;

    if (!currentPublications.length) {
        return <div>No publications found</div>;
    }

    return (
        <div className='w-full  flex flex-col items-center justify-center p-10'>
            {currentPublications.map((currentPublication, index) => (
                <div key={index} className='w-full gap-2 max-w-7xl flex flex-col lg:flex-row mb-10 cursor-pointer' onClick={() => {
                    const { url, medianame, publisheddate, title } = currentPublication;
                    const formattedPublishedDate = publisheddate.replace(/[\s,.-]+/g, '-');
                    const formattedTitle = title.replace(/[\s,.-]+/g, '-');
                    const encodedUrl = `${url}/${medianame}/${encodeURIComponent(formattedPublishedDate)}/${encodeURIComponent(formattedTitle)}`;
                    window.open(encodedUrl, '_blank', 'noopener,noreferrer');
                }}>
                    <div className='h-full pb-2'>
                        <img src={currentPublication.medialogo} alt='Publication Logo' />
                    </div>
                    <div className='w-full h-full'>
                        <div id='date' className='font-normal text-md pb-2 font-thin text-blue-800'>{currentPublication.publisheddate}</div>
                        <div id='title' className='text-lg text-black font-bold'>{currentPublication.title}</div>
                        <div id='description' className='font-normal text-md py-5 text-gray-500' dangerouslySetInnerHTML={{ __html: currentPublication.description }}></div>
                    </div>
                </div>
            ))}
            {publications.length > publicationsPerPage && (
                <div className='mt-auto flex gap-2 justify-center w-full max-w-3xl'>
                    {showArrows && (
                        <button
                            onClick={() => setCurrentPage(currentPage - 1)}
                            disabled={currentPage === 1}
                            className='px-3 py-2 bg-blue-500 text-white rounded disabled:opacity-50 mr-2'
                        >
                            {'<'}
                        </button>
                    )}
                    {renderPageNumbers}
                    {showArrows && (
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className='px-3 py-2 bg-blue-500 text-white rounded disabled:opacity-50 ml-2'
                        >
                            {'>'}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}
