import React from 'react'
import { ImFacebook2, ImInstagram, ImLinkedin } from "react-icons/im";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiGlassdoor } from 'react-icons/si'
import { Link } from 'react-router-dom';


const Footer = () => {

  return (
    <>
      <div className='bg-veniceBlue flex flex-col md:flex-row items-center lg:px-32 xl:px-32 justify-between md:px-8'>
        <div className='md:hidden mt-5 text-white text-base tracking-wider flex flex-col justify-center '>
          <p>sales@decisionminds.com</p>
          <p>career@decisionminds.com</p>
        </div>
        <div className='flex gap-7 text-white text-4xl justify-center w-full my-5 md:justify-start'>
          <Link to='https://www.facebook.com/decisionmindscorp'><ImFacebook2 /></Link>
          <Link to='https://twitter.com/DecisionMinds'><FaSquareXTwitter /></Link>
          <Link to='https://www.linkedin.com/company/decision-minds'><ImLinkedin /></Link>
          <Link to='https://www.instagram.com/decisionminds/'><ImInstagram /> </Link>
          <Link to='https://www.glassdoor.com/Overview/Working-at-Decision-Minds-EI_IE1433524.11,25.htm'><SiGlassdoor /></Link>
        </div>
        <div className='hidden md:block text-white text-base tracking-wider '>
          <p>sales@decisionminds.com</p>
          <p>career@decisionminds.com</p>
        </div>
      </div>
      <div className='h-3 bg-orange'></div>
    </>

  )
}

export default Footer