import React from 'react'

export default function HighlightText({ titleWidth, prefix, suffix, highlighttext, description }) {
  return (
    <div className='flex flex-start '>
      <div className={` font-lato `}>
        <div className='font-bold flex text-[20px] md:text-[30px] xl:text-[40px]'>
          <div>{prefix}</div>
          <div className='border-b-4 border-orange border-solid '>{highlighttext}</div>
          <div>{suffix}</div>
        </div>
        <span className='text-veniceBlue font-bold flex pb-3 text-[16px] md:mt-[-10px] lg:mt-[-15px] md:text-[20px] md:justify-end  lg:text-[20px]'>{description}</span>
      </div>
    </div>
  )
}
