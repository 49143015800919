import React from 'react';
import Handshake from '../Images/banner.svg';
import DMIcon from '../Images/DMIconWhite.png'
import section1Img from '../Images/section1.svg'
import section2Img from '../Images/section2.svg'
import section3Img from '../Images/section3.svg'
import HighlightText from './HighlightText';
import ContactUs from '../ContactUs/ContactUs';
import { useNavigate } from 'react-router-dom';


export default function Blog() {
    const navigate = useNavigate()
    const useCaseSection = [
        {
            prefix: '',
            highlighttext: 'DEEP',
            suffix: 'EN MEMBER RELATIONSHIPS',
            forText: 'For: Marketing, Sales',
            description: 'We combine demographics with behavior to create dynamic customer profiles–who, where and how they act and interact. Uncover trends to enable proactive engagement and service. ',
            list: ['Segment clients and channels by behavior, demographics, and profitability',
                'Optimize marketing and sales efforts with proactive customer engagement, cross sell and upsell.',
                'Drive product and channel adoption with sales momentum and trend tracking.',
                'Continually refine product, pricing, promotion, and place mix. ',
                'Increase customer engagement and NPS.'],
            img: section1Img,
            Order: { one: 'lg:order-1', two: 'lg:order-2' },
            bgColor: 'bg-white',
            marginLeft: 'lg:ml-[0vw]',
            titleWidth: 'lg:w-[60vw]'
        },
        {
            prefix: 'DECIDE\u00A0AND\u00A0',
            highlighttext: 'EXECUTE',
            suffix: '\u00A0MORE CONFIDENTLY',
            forText: 'For: Marketing, IT and Executives',
            description: 'Get a higher-level overview and more detailed understanding of the business. Make better business decisions, increase operational efficiency, and optimize investment.',
            list: ['Make faster, more effective decisions on product, channel, member, and technology investments. ',
                'Improve efficiency & productivity with trusted insights. Stop wasting time tracking down & confirming data. ',
                'Uncover high margin products, revenue-driving product lines, most profitable channels, and locations (ATM, branch, online), more easily.'],
            img: section2Img,
            Order: { one: 'lg:order-2', two: 'lg:order-1' },
            bgColor: 'bg-[#f5f8fb]',
            marginLeft: 'lg:ml-[18vw]',
            titleWidth: 'lg:w-[74vw]'
        },
        {

            prefix: `MANAGE\u00A0`,
            highlighttext: 'RISK\u00A0',
            suffix: ' MORE EFFECTIVELY',
            forText: 'For: Finance, Risk, Credit leaders',
            description: 'Illuminate the areas of interest rate, liquidity, credit, capital, and fraud that are most at risk. Target and strengthen prevention to ensure regulatory compliance and reduce risk of loss.',
            list: ['Demonstrate complete, timely, and accurate risk data aggregation.',
                'Automate KRIs and early warning indicators (capital, credit, liquidity, market, reputational, operational).',
                'Take action to reduce fraud exposure with education, stricter controls, variable rate limits and more.'],
            img: section3Img,
            Order: { one: 'lg:order-1', two: 'lg:order-2' },
            bgColor: 'bg-white',
            marginLeft: 'lg:ml-[160px]',
            titleWidth: 'lg:w-[70vw]'
        }
    ]
    return (
        <div className='font-[lato]' >
            <div className='w-full flex flex-col xl:flex-row font-lato overflow-hidden '>
                <div className='w-full xl:w-[70%]  relative'>
                    <div className='relative'>
                        <img src={Handshake} alt='Handshake' />
                        <div className='lg:px-8'>
                            <div className='absolute bottom-3 md:block hidden lg:bottom-10 w-100 lg:left-10 left-6 px-2 backdrop-blur-md lg:bg-[#085690]/60 bg-[#085690]/60 md:text-4xl text-white'>
                                <p className='font-lato font-light'> Reliable <br />
                                    <span className='font-lato font-bold'>Data and Analytics Insights</span><br />
                                    <span className='text-grey font-bold'>for</span> <span className='font-lato font-bold'>Regional Banks</span><span className='text-grey font-bold'> and</span>  <span className='font-lato font-bold'>Credit Unions</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' bottom-3 block md:hidden text-[35px]  w-100  left-6  bg-[#454545] backdrop-blur p-6  text-white'>
                    <p className='font-lato font-light'> Reliable <br />
                        <span className='font-lato font-bold'>Data and Analytics Insights</span><br />
                        <span className='text-grey font-bold'>for</span> <span className='font-lato font-bold'>Regional Banks</span><span className='text-grey font-bold'> and</span>  <span className='font-lato font-bold'>Credit Unions</span>
                    </p>
                </div>
                <div className='w-full xl:w-[30%] bg-veniceBlue flex flex-col items-center md:px-6 py-3 xl:py-6 '>
                    <div className='flex justify-center items-center w-3/4 '>
                        <img className='xl:block hidden xl:px-24 px-5 xl:py-5 opacity-30 ' src={DMIcon} alt='logo' />
                    </div>
                    <div className='overflow-hidden px-6 '>
                        <h3 className='text-white font-lato tracking-wider leading-7 '>
                            Credit Unions and Regional Banks need data insights more than ever. But legacy systems and new digital channels make uncovering those insights challenging. Decision Minds’ industry-leading Data & Analytics solutions unlock invaluable data and business insight, empowering you to deepen customer relationships, make more confident decisions, and strengthen risk management.
                            Our track record proves we deliver results and data you can trust.
                        </h3>
                    </div>
                </div>
            </div>
            <section className='bg-[#f5f9fc]  w-full flex flex-col items-center gap-5 md:gap-0 md:flex-row md:items-center lg:justify-end py-14 '>
                <div className='w-[90%] md: w-2/3 lg:w-1/2 md:border md:border-t-0 md:border-b-0 md:border-l-0 md:border-grey-600 md:border-r-4  md:flex md:justify-center'>
                    <p className='2xl:text-[20px]  text-regular font-[lato] font-normal leading-normal md:px-12 tracking-wider'>Explore press releases and company-related articles for insights into our latest developments, achievements, and industry updates related to regional banks and credit unions. Stay informed with our comprehensive coverage</p>
                </div>
                <div className='flex justify-end md:justify-center items-center w-[90%] md:w-[30%]'>
                    <button className='text-veniceBlue md:bg-veniceBlue md:text-white font-[lato]  md:px-10 md:py-5 2xl:text-[32px] font-bold tracking-wider rounded' onClick={() => { navigate('/press') }}>Read Now</button>
                </div>
            </section>

            <div>
                <ContactUs />
            </div>

            <section className='relative lg:py-8'>
                {useCaseSection.map((data, datakey) => {
                    return (
                        <div key={datakey} className={`font-nunito-sans flex items-center justify-center relative  px-6 lg:px-[3%] ${data.bgColor} `}>
                            <div className={`grid  lg:grid-row-2 lg:grid-flow-col lg:py-10 py-5 gap-4 w-[100%] `}>
                                <div className={`${data.Order.one} ${data.titleWidth} `}>
                                    <div>
                                        <div>
                                            <HighlightText prefix={data.prefix} highlighttext={data.highlighttext} suffix={data.suffix} description={data.forText} />
                                        </div>
                                        <div className={`${data.Order.two} block xl:hidden flex justify-center items-center p-[40px]`}>
                                            <img src={data.img} />
                                        </div>
                                        <div className={`wrap xl:w-[50vw] ${data.marginLeft}`}>
                                            <p className='mb-[15px] font-lato font-regular text-base text-lightgrey'>{data.description}</p>
                                            <ul className='list-disc list-inside'>
                                                {data?.list.map((listData, listIndex) => (
                                                    <li className='font-lato font-regular text-base text-lightgrey list-outside' key={listIndex}>
                                                        {listData}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div className={`${data.Order.two} xl:block hidden flex justify-center items-center w-[350px]`}>
                                    <img src={data.img} />
                                </div>

                            </div>
                        </div>
                    )
                })}
            </section>
            <section className='relative bg-veniceBlue text-white flex   '>
                <div className='absolute overflow-hidden lg:w-[300px] w-[200px] left-[-60px] lg:top-[-20px]  transparent opacity-30'>
                    <img src={DMIcon} alt="Decision Minds Icon" className='w-full h-auto' />
                </div>
                <div className='w-full px-6 lg:px-[3%]'>
                    <div className=' text-white text-center font-lato font-bold text-[20px] md:text-[30px] xl:text-[40px] mt-8 xl:mt-5 '>DATA AND EXECUTION YOU CAN RELY ON</div>
                    <div className='flex flex-col md:flex-row  md:items-center gap-5 md:gap-10 items-left justify-left text-left  md:justify-center lg:text-center md:px-20 py-10'>
                        <div className=' lg:px-10 '>
                            <h3 className=' font-bold font-lato lg:text-[28px] text-[20px] text-milkywhite mb-2'>Trusted Data</h3>
                            <p className=' font-regular font-lato text-base text-milkywhite'>Data quality is a prerequisite for building trust in any analytics initiative. Ensuring correctness is the first step. We standardize data by consolidating households, cleaning up addresses, establishing a Global Member ID and more. Make better informed decisions with trusted data. </p>
                        </div>
                        <div class="hidden md:block mx-auto h-40 border-l-[6px] border-solid border-milkywhite"></div>
                        <div className='lg:px-10 '>
                            <h3 className=' font-bold font-lato lg:text-[28px]  text-[20px] text-milkywhite mb-2'>Execution Excellence</h3>
                            <p className=' font-regular font-lato text-base text-milkywhite '>With Decision Minds’ proven performance for hundreds of clients including Fortune 500 leaders, you can trust the outcome. We combine industry expertise, deep technology backbone, IP accelerators, offshore economics, with our agile delivery framework. Count on our cost-effective solution to deliver high value results, fast time to value, and measurable ROI. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='lg:px-[10%] px-6 lg:px-[10%] pt-4'>
                <div className='flex gap-2  justify-start items-baseline '>
                    <span className=' font-light font-lato text-[30px] lg:text-xl   flex gap-2'>PROOF</span>
                    <span className='font-medium font-lato md:text-[32px]  flex gap-2 '>IN</span >
                    <span className='font-light text-[30px]  lg:text-[55px]   text-black'>THE</span>
                    <span className='font-bold font-lato text-[30px]  lg:text-[55px]  text-veniceBlue'>RESULTS</span>
                </div>
                <div className='pb-5'>
                    <div className='flex flex-col lg:flex-row  lg:gap-10 lg:items-center py-5'>
                        <div className='font-light font-lato lg:text-[96px] text-[40px] '>01</div>
                        <div className='flex flex-col justify-center align-center'>
                            <h1 className='font-bold lg:text-[30px] text-lg font-lato'>$200B Regional Bank :</h1>
                            <ul className=' ml-[20px] text-lightgrey font-lato font-regular text-base  py-2'>
                                <li>Implemented daily product, segment, and pricing transparency to improve client insight, sales & marketing focus, promo effectiveness in 6 months. </li>
                                <li>Laser-focused member outreach drove cross sell and growth resulting in over $5B and 5% YoY deposit growth. </li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:items-center lg:gap-10'>
                        <div className='font-light font-lato lg:text-[96px] text-[40px]  '>02</div>
                        <div className='flex flex-col justify-center align-center'>
                            <h1 className='font-bold lg:text-[30px] text-lg font-lato'>Western Regional Bank: </h1>
                            <ul className=' ml-[20px] text-lightgrey font-lato font-regular text-base  py-2'>
                                <li>Connected tech-focused data infrastructure investments to business leadership priorities in 6 months.</li>
                                <li>Enabled business outcomes including sales enablement, digital experience, and process automation.  </li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:items-center lg:gap-10 py-5'>
                        <div className='font-light font-lato lg:text-[96px] text-[40px]'>03</div>
                        <div className='flex flex-col justify-center align-center'>
                            <h1 className='font-bold lg:text-[30px] text-lg font-lato'>$12B Credit Union:</h1>
                            <ul className=' ml-[20px] text-lightgrey font-lato font-regular text-base  py-2'>
                                <li>Rolled out automated and accurate operational metrics, complete and trusted data in 4 months. </li>
                                <li>The bank received near real-time visibility to KPIs, 6-point NPS increase and $1.2M in savings due to process automation. Up to 80% employee adoption of data driven decision-making. </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
