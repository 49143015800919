import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser'
import FemaleBusinessProfile from '../Images/FemaleBusinessProfile.png'
import DmOffCutLogo from '../Images/DmOffCutLogo.png'
import ZohoContact from './ZohoContact';

export default function ContactUs() {
    const form = useRef();
    const [showSuccess, setShowSuccess] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm('service_mv1s4wk', 'template_nnn6r8m', form.current, 'hDJMfMQdKR0VSv1xp')
            .then(
                (result) => {
                    setShowSuccess(true);
                    setTimeout(() => {
                        setShowSuccess(false);
                    }, 2000);
                    form.current.reset();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (


        <section className='bg-veniceBlue md:pl-12 lg:px-20 xl:px-0 md:flex xl:justify-between w-full  '>
            <div className='flex flex-col justify-center items-center md:items-start md:flex-row md:justify-evenly 2xl:justify-between w-full 2xl:w-[55%] 2xl:pl-36'>
                <div className='w-64 h-96 relative'>
                    <img className='w-64 absolute left-24 ' src={DmOffCutLogo} />
                    <div className='w-full h-full absolute p-0 m-0 bottom-16 '>
                        <img className='w-full h-full' src={FemaleBusinessProfile} />
                        <p className='text-white text-lg font-light leading-snug p-0 m-0 text-center mt-2'><b>Liz Seither,</b> Account Executive</p>
                    </div>
                </div>
                <div className='w-auto md:h-96 flex justify-center items-center '>
                    <p className='text-white  2xl:text-5xl font-thin leading-snug p-0 m-0 text-4xl'><span className='font-black'>Learn</span> how we can <br /> help you <span className='font-black'>leverage</span><br /> <span className='font-black'>data</span> you can trust</p>
                </div>
            </div>
            <div className=' h-auto p-0 md:flex md:justify-end'>
            <ZohoContact/>     
            </div>

                  </section>
    );
}