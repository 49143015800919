import React from 'react';
import emailjs from '@emailjs/browser';
import DMwhiteLogo from '../Images/DMIconWhite.png';
import ZohoContact from './ZohoContact';

export default function GeneralContactUs() {
    const sendEmail = (e) => {
        e.preventDefault();
        const form = e.target;
        emailjs
            .sendForm('service_mv1s4wk', 'template_nnn6r8m', form, 'hDJMfMQdKR0VSv1xp')
            .then(
                (result) => {
                    // Handle success
                    console.log('Email sent successfully');
                    form.reset();
                },
                (error) => {
                    // Handle error
                    console.error('Email sending failed:', error.text);
                }
            );
    };

    return (
        <div className='bg-veniceBlue flex flex-col justify-center items-center p-5 lg:flex-row md:items-center md:justify-between'>
            <img className='w-40 md:w-60 opacity-30' src={DMwhiteLogo} alt='DM Icon' />
            <div className='text-white px-10 text-center text-4xl lg:text-5xl'>
                <span className='font-extrabold'>Learn</span>
                <span className='font-thin'> how we can help you</span>
                <span className='font-extrabold'> leverage data </span>
                <span className='font-thin'>you can trust</span>
            </div>
            <div className='w-1/3 flex items-center justify-center'>
                <ZohoContact />
            </div>
        </div>
    );
}
