import React, { useEffect } from 'react';
import Handshake from '../Images/Mediaimg.svg';
import GeneralContactUs from '../ContactUs/GeneralContactUs';
import { MediaPublishedJson } from '../MediaSpotlight/MediaPublishedJson';
import { Link, useNavigate } from 'react-router-dom';

export default function News() {
  const content = MediaPublishedJson.Publications;
  const navigate = useNavigate();
  useEffect(() => {
    // Get the element by ID
    const span = document.getElementById('clickableSpan');
    span.style.cursor = 'pointer';
    span.style.color = '#085690';
    span.style.fontWeight = '700';

    // Define the click handler function
    const handleClick = () => {
      navigate('/')
    };

    // Attach the event listener
    span.addEventListener('click', handleClick);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      span.removeEventListener('click', handleClick);
      span.style.cursor = '';
      span.style.color = '';
      span.style.fontWeight = '';

    };
  }, []); // Empty dependency array means this effect runs only once after the initial render



  return (
    <>
      <div className='w-full fixed relative overflow-hidden'>
        <img className='w-full h-full object-cover' src={Handshake} alt='Handshake' />
        <div className='absolute top-0 left-0 w-full h-full bg-[#085690BF]'></div>
        <div className='absolute inset-0 flex items-center justify-center  text-white text-center'>
          <div className='font-thin text-xl bg-[#38668a] px-10 py-5'><span className='font-extrabold'>News</span></div>
        </div>
      </div>

      <div className='w-full flex flex-col items-center justify-center p-10'>
        <div className='max-w-7xl font-normal text-md py-5 text-gray-500'>
          {content.map((publication, index) => (
            <div key={index}>
              <div onClick={() => window.open(`${publication.link}`, '_blank')} className='h-full pb-2 cursor-pointer'>
                <img src={publication.medialogo} alt='Publication Logo' />
              </div>
              <div id='date' className='font-normal text-md pb-2 font-thin text-blue-800'>{publication.publisheddate}</div>
              <div id='title' className='text-lg text-black font-bold'>{publication.title}</div>
              <div>{publication.subHeading}</div>
              <div id='description' className='font-normal text-md py-5 text-gray-500' dangerouslySetInnerHTML={{ __html: publication.description }}></div>
              <div className='flex flex-col xl:flex-row w-full xl:items-center'>
                <div className={`${publication.imgSection.imgalign}`}>
                  <img className={`${publication.imgSection.imgHeight}${publication.imgSection.imgWidth} w-full`} src={`${publication.imgSection.img}`} />
                </div>
                <div className='text-4xl xl:w-[450px] text-black pb-3 pr-3'>
                  <h2 className='leading-normal'>{publication.imgSection.content}</h2>
                  <h2 className='text-2xl mt-5 font-semibold'>{publication.imgSection.wittenBy}</h2>
                  <h4 className='text-lg leading-10'>{publication.imgSection.writerDesignation}</h4>
                </div>
              </div>
              <div className='pb-10'>
                {publication.newsAbout.map((newscontent, index) => (
                  <p key={index} className={`${newscontent.lineHeight} ${newscontent.marginTop}`}>
                    {newscontent.data}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <GeneralContactUs />
      </div>
    </>
  );
}
