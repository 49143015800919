import EINpresswire from '../Images/Publications/einpresswire.svg';
import EINpresswireimg from '../Images/Publications/einpresswirebreifimg.svg'

export const MediaPublishedJson = {
  Publications: [
    {
      medialogo: EINpresswire,
      publisheddate: "March 05, 2024",
      title: "Decision Minds Extends Industry-Leading Data Analytics Solution to Credit Unions and Regional Banks",
      subHeading: "Decision Minds’ purpose-built solution enables member 360 views, risk spotlight and mitigation, and operational optimization on a foundation of trustworthy data.",
      description: "<span>Decision Minds,  the enterprise transformation IT services and solutions leader,  announced today it is expanding its data analytics and insights solution  to the <span id ='clickableSpan' >credit union and regional banking space</span>.  Organizations in this space are striving for growth while dealing with  unique challenges and opportunities. They need to increase deposits,  strengthen member and customer relationships, reduce financial risk, and  rationalize complicated tech stacks all while operating with limited  resources in an increasingly rigorous regulatory environment. </span>",
      url: "/news",
      medianame: "EINpresswire",
      link: 'https://www.einpresswire.com/article/691995878/decision-minds-extends-industry-leading-data-analytics-solution-to-credit-unions-and-regional-banks',
      imgSection: {
        imgalign: 'xl:order-last',
        img: EINpresswireimg,
        imgWidth: 'xl:w-full',
        imgHeight: 'xl:h-[540px]',
        content: ' “We  intend to reshape this market so that credit unions and community banks  can harness the data they need to drive the growth they require to  remain competitive."',
        wittenBy: '-Balaji Rajagopalan,',
        writerDesignation: 'Decision Minds, Founder & CEO'
      },
      newsAbout: [
        { data: 'Decision Minds offers a solution to quickly and cost effectively help  organizations achieve the data insight necessary to address those  challenges.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: '"Working with credit unions and community banks over the last ten years,  we observed that they are drowning in data from disparate sources. They  simply have to gain control of that data in order to mature and move to  the next level,” said Balaji Rajagopalan, Decision Minds Founder and  CEO. “At the same time, there was a gap in the solutions available to  tackle that challenge. We’ve invested in building out our capability to  fill that gap.”', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: '“We intend to reshape this market so that credit unions and community  banks can harness the data they need to drive the growth they require to  remain competitive,” Rajagopalan continued.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: ' “Decision Minds has supported First Tech for nearly ten years while  serving as a key partner and subject matter expert in data  mapping/integration and other key parts of our business,” said Greg  Mitchell, President and CEO, First Tech Federal Credit Union.  “Our  collective focus on ‘qualitative’ solutions that deliver high fidelity  data delivered a vital ‘unlock’. This allowed First Tech, and other  progressive CUs and community banks, to have confidence in their data  when making key risk decisions or delivering superior personalization  experiences for their members. The obsession with ‘quality’ is a  hallmark of Decision Minds.”', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: ' “Data and the effective deployment of data are incredibly important to  understanding, maintaining, and growing credit union member  relationships,” said Jay Franklin, Senior Vice President, Enterprise  Data and Analytics, First Tech Federal Credit Union.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: ' Leveraging Decision Minds’ proven sprints to value and scalable  engagement model, the data analytics solution supports marketing, sales,  risk, executive and operational goals in three key focus areas:', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: ' • Member insights and deposit growth – combine demographics with  activity to create dynamic 360 member profiles – who, where and how they  act and interact. Uncover trends to enable proactive engagement and  service, improve member experience, and optimize growth programs  including deposit offers.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: ' • Fraud and credit risk mitigation – Illuminate areas of interest rate,  liquidity, credit, capital, and fraud risk. Target and strengthen  prevention to ensure regulatory compliance and reduce risk of loss.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: '  • Business optimization and IT efficiency – Gain a higher-level overview  and more detailed understanding of operations. See profitability by  channels, by member groups and by products to cut costs and optimize  spending. Harvest value from technology investments.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: ' This solution expands Decision Minds’ years of proven performance for  hundreds of clients including billion-dollar regional banks and credit  unions to the broader community banking space. The company’s industry  expertise, deep technology backbone, IP accelerators, offshore  economics, and agile delivery framework combine to create a formidable  yet cost-effective solution that delivers high value results, fast time  to value, and measurable ROI to credit unions and regional banks who  need to capitalize on their data resources.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: ' About Decision Minds ', lineHeight: "leading-relaxed", marginTop: "mt-3 font-semibold" },
        { data: 'Decision Minds is the enterprise digital transformation IT services and  solutions leader in Data Cloud, Data and Cloud Operations, Digital  Engineering and Gen AI. Our ready-to-deploy services, solutions, and  tech-savvy team of experts in emerging technologies help our clients  utilize data quickly and cost effectively. Our strategic consulting,  managed services and support across banking, technology, and retail  verticals has enabled over 300 organizations including Fortune 500  enterprises to better understand their customers and operations,  delivering measurable cost savings and significant growth.', lineHeight: "leading-relaxed", marginTop: "mt-3" },
        { data: 'Kim Cook', lineHeight: "leading-relaxed", marginTop: "mt-3 font-medium" },
        { data: 'Decision Minds', lineHeight: "leading-relaxed", marginTop: "font-semibold" },
        { data: 'kimc@decisionminds.com', lineHeight: "leading-relaxed", marginTop: "italic" },
      ]
    },
  ]
};