import React, { Children, useState } from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'

const Navbar = (props) => {

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [openMobileDropdown, setOpenMobileDropdown] = useState(null);

  const mobileToggleDropdown = (label) => {
    console.log("hhhh")
    setOpenMobileDropdown((prev) => (prev === label ? null : label));

  };

  return (
    <div >
      <div class="fixed w-full top-0 z-50 bg-white shadow-md ">
        <Header scrollToContact={scrollToContact} mobileToggleDropdown={mobileToggleDropdown} openMobileDropdown={openMobileDropdown} />
      </div>

      <div className='mt-20'>
        {/* <Outlet /> */}
        {props.children}
      </div>

      <Footer />

    </div>
  )
}

export default Navbar